import React from 'react'
import {Form, Button} from 'react-bootstrap'
import ChildAgeSelection from './ChildAgeSelection'

class CardForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form_validated: false,
            recipient_email: '',
            email: '',
            name: '',
            phone: '',
            child_name: '',
            child_age: '',
            cardConfiguration: props.cardConfiguration,
            dirty: false,
            isGift: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cardConfiguration !== this.props.cardConfiguration) {
            this.setState({
                cardConfiguration: this.props.cardConfiguration
            })
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            dirty: true,
            [name]: value
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({form_validated: true})
            return
        }

        this.setState({form_validated: true});

        this.props.handleSubmit(this.state)
    }

    render() {
        const childAges = this.state.cardConfiguration.isAfterSchool ? [
            "3 ans",
            "4 ans",
            "5 ans",
            "6 ans et plus",
        ] : null

        return (
            <Form
                validated={this.state.form_validated}
                onSubmit={this.handleSubmit}
                className="border rounded px-4 needs-validation" noValidate>
                <Form.Group className="my-2 mx-2">
                    <Form.Label>Votre nom et prénom *</Form.Label>
                    <Form.Control
                        name="name" onChange={this.handleChange}
                        value={this.state.name}
                        required
                    />
                </Form.Group>

                <Form.Group className="my-2 mx-2">
                    <Form.Label>Votre adresse email *</Form.Label>
                    <Form.Control
                        name="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                        type="email"
                        pattern=".+@.+\..+"
                        required
                    />
                </Form.Group>

                <Form.Group className="my-2 mx-2">
                    <Form.Label>Numéro de téléphone</Form.Label>
                    <Form.Control
                        type="tel"
                        placeholder="Numéro de téléphone"
                        pattern="([0-9]{2}[. -]?){5}"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.handleChange}
                    />
                </Form.Group>

                <Form.Check
                    className="my-2 mx-2"
                    type="checkbox"
                    name="isGift"
                    onChange={this.handleChange}
                    value={this.state.isGift}
                    label="Cette carte d'accès est un cadeau ?"
                />

                {
                    this.state.cardConfiguration.isUnlimited && !this.state.isGift ? (
                        <>
                            <Form.Group className="my-2 mx-2">
                                <Form.Label>Prénom de l'enfant *</Form.Label>
                                <Form.Control
                                    name="child_name"
                                    onChange={this.handleChange}
                                    value={this.state.child_name}
                                    required
                                />
                            </Form.Group>
                            <ChildAgeSelection className="my-2 mx-2" ages={childAges}
                                               handleSetAge={(age) => this.setState({child_age: age})}/>
                        </>
                    ) : ''

                }

                <Button
                    disabled={!this.state.dirty}
                    type="submit"
                    className="btn btn-green my-4"
                >
                    Commander une carte d'accès
                </Button>

            </Form>
        )
    }
}

export default CardForm
