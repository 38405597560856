import React from 'react'
import { Form, Button } from 'react-bootstrap'

class CardAdminForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cardConfiguration: {...props.cardConfiguration},
            form_validated: false,
            dirty: false,
            workshopTypes: ["Ateliers parent-enfant", "Ateliers jeunes parents", "Formation professionnelle", "Enfants sans leurs parents"]
        }
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidUpdate(prevProps) {
        if(prevProps.cardConfiguration !== this.props.cardConfiguration) {
            this.setState({
                cardConfiguration: this.props.cardConfiguration
            })
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            dirty: true,
            cardConfiguration: {
                ...this.state.cardConfiguration,
                [name]: value
            }
        })
    }

    renderForm() {
        return <>
            <Form.Label className="my-2 mx-2">Prix</Form.Label>
            <Form.Control
                name="price" type="number" onChange={this.handleChange} 
                value={this.state.cardConfiguration.price}
                required step="0.01"
            />

            <Form.Label className="my-2 mx-2">Nombre d'utilisations</Form.Label>
            <Form.Control
                name="maxBookings" type="number" onChange={this.handleChange}
                value={this.state.cardConfiguration.maxBookings}
                required
            />
            <Form.Label className="my-2 mx-2">Nombre de mois jusqu'à expiration après la première utilisation</Form.Label>
            <Form.Control
                name="durationInMonth" type="number" onChange={this.handleChange}
                value={this.state.cardConfiguration.durationInMonth}
                required
            />

            <Form.Label className="my-2 mx-2">Nombre d'ateliers gratuit</Form.Label>
            <Form.Control
                name="maxWorkshops" type="number" onChange={this.handleChange}
                value={this.state.cardConfiguration.maxWorkshops}
                required
            />

            <Form.Label className="my-2 mx-2">Restreindre l'utilisation de cette carte à un type d'atelier</Form.Label>
            <Form.Control as="select" name="workshopType"
                          value={this.state.cardConfiguration.workshopType} onChange={this.handleChange} >
                <option key={'none'} value={null}>Pas de restriction</option>
                {
                    this.state.workshopTypes.map(workshopType => {
                        return <option key={'type_' + workshopType} value={workshopType}>{workshopType}</option>
                    })
                }
            </Form.Control>


            <Button 
                disabled={! this.state.dirty}
                type="submit"
                className="btn btn-green d-block mx-auto my-4"
            >
                Enregistrer les modifications
            </Button>
        </>
    }

    render() {
        return (
                <Form
                    validated={this.state.form_validated} 
                    onSubmit={(e) => {
                        e.preventDefault()
                        this.setState({dirty: false})
                        this.props.handleSubmit(this.state.cardConfiguration)}
                    }
                    className={"px-4 needs-validation " + this.props.className} noValidate>
                    {
                        this.renderForm()
                    }
                    
                </Form>
        )
    }
}

export default CardAdminForm
