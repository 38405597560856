import React from "react"
import { login, isAuthenticated } from "../utils/auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isAuthenticated()) {
    login()
    return null
  }
  return <Component {...rest} />
}
export default PrivateRoute
