import React from 'react'
import { Container, Tabs, Tab } from 'react-bootstrap'
import CardsAdmin from './CardsAdmin'
import BookingsAdmin from '../components/BookingsAdmin'
import WorkshopsAdmin from '../components/WorkshopsAdmin'

class Admin extends React.Component {
  constructor(...args) {
    super(...args)

    this.state = {
      activeTab: 'bookings'
    }
  }

  render() {

    return (
      <Container className="my-4">
        <h3>Espace administrateur</h3>
        <Tabs activeKey={this.state.activeTab} onSelect={(k) => this.setState({activeTab: k})} transition={false} className="my-4">
          <Tab eventKey="bookings" title="Réservations">
            <BookingsAdmin />
          </Tab>
          <Tab eventKey="workshops" title="Ateliers">
            <WorkshopsAdmin />
          </Tab>
          <Tab eventKey="cards" title="Cartes d'accès">
            <CardsAdmin />
          </Tab>
        </Tabs>
      </Container>
    )
  }
}

export default Admin
