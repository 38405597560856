import React from 'react'
import { Alert, Button, Col, Card, Row, Modal } from 'react-bootstrap'
import axios from "axios"
import { StaticQuery, graphql } from "gatsby"
import { getIdToken } from '../utils/auth'
import CardAdminForm from './CardAdminForm'
import CardForm from './CardForm'

class CardsAdmin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error_msg: '',
            cardsConfiguration: {},
            modal: false,
            currentCard: null,
        }

        this.updateCardConfiguration = this.updateCardConfiguration.bind(this)
        this.createCard = this.createCard.bind(this)
    }

    componentDidMount() {
        this.loadCardsConfiguration()
    }

    loadCardsConfiguration() {
        axios({method: 'get', url:'/.netlify/functions/cardsConfiguration'}).then((response) => {
            this.setState({cardsConfiguration: response.data.cards_configuration})
            return response.data.cards_configuration
        }).catch(err => {
            this.setState({
                cardsConfiguration: {}
            })
        })
    }

    getCards(render) {
        return <StaticQuery
            query={graphql`
            query CardsAdmin {
                cards:mdx(frontmatter: {slug: {eq: "/cartesacces"}}) {
                    frontmatter {
                        cards {
                            name
                        }
                    }
                }
            }
        `}
        render={(data) => render(data.cards.frontmatter.cards)}/>
    }

    updateCardConfiguration(cardConfiguration) {
        const newCardsConfiguration = {
            ...this.state.cardsConfiguration,
            [cardConfiguration.cardName]: cardConfiguration
        }

        this.setState({
            error_msg: '',
            cardsConfiguration: newCardsConfiguration
        })

        axios({
            method: 'post', url:'/.netlify/functions/cardsConfiguration', 
            data: {cards_configuration: newCardsConfiguration},
            headers: {token: getIdToken()}
        }).then(response => {
            /* this.setState({currentWorkshop: {...this.state.currentWorkshop, dirty: false}}) */
        }).catch(err => {
            console.log(err)
            this.setState({error_msg: "Erreur lors de la sauvegarde"})
        })
    }

    hideModal() {
        this.setState({currentWorkshop: null})
    }

    createCard(data) {
        this.setState({modal: false})
        axios({method: 'post', url:'/.netlify/functions/cardCreate', headers:{
            token: getIdToken()
        }, data: data}).then(response => {
            this.setState({error_msg: "La carte d'accès a été créé avec succès"})
          
        }).catch((err) => {
          console.log(err)
          this.setState({error_msg: "[Erreur] Le carte d'accès pas pas pu être créé"})
        })
    }

    renderModal() {
        if (this.state.currentCard) {
            return <Modal show={this.state.modal} onHide={() => this.setState({modal: false})} centered>
            <Modal.Header closeButton>
                <Modal.Title>Creer: {this.state.currentCard.cardName}</Modal.Title>
            </Modal.Header>
            <CardForm 
                cardConfiguration={this.state.currentCard} 
                handleSubmit={
                    (form) => {
                      this.createCard({
                        card_name: this.state.currentCard.cardName,
                        user_form: form
                      })
                    }
                }
            />
        </Modal>
        }
        return ''
        
    }

    render() {
        
        return this.getCards(cards => {
            return (
                <>
             <Row className="row-cols-1 row-cols-md-4 align-self-center">
                
                {
                    this.renderModal()
                }
                 {

                     cards.map((card, index) => {
                         const cardConfiguration = this.state.cardsConfiguration[card.name] || {
                            isUnlimited: false,
                            price: 0,
                            isBuyable: true,
                            isActive: true,
                            durationInMonth: 0,
                            maxBookings: 0,
                            maxWorkshops: 0,
                            birthdayDiscount: 0,
                            cardName: card.name,
                            isAfterSchool: false,
                            workshopType: null,
                         }
                         return (
                            <Col key={"card-" + index} className="mb-4">
                                <Card>
                                    <Card.Body>
                                        <Card.Title className="text-center">{card.name}</Card.Title>
                                        <CardAdminForm 
                                        cardConfiguration={cardConfiguration}
                                        handleSubmit={this.updateCardConfiguration}
                                        />
                                        
                                    </Card.Body>
                                    <Card.Footer>
                                        {
                                            cardConfiguration.price > 0 && cardConfiguration.isActive ? (
                                                <Button 
                                                onClick={() => this.setState({
                                                    currentCard: cardConfiguration,
                                                    modal: true,
                                                    error_msg: ''
                                                })}
                                                className="mx-auto d-block"
                                                >
                                                    Créer une nouvelle carte d'accès
                                                </Button>
                                            ) : ''
                                        }
                                    </Card.Footer>
                                </Card>
                            </Col>
                        )

                     })
                 }
                 
                </Row>
                <Alert variant="info" className={this.state.error_msg ? '': 'd-none'}>{this.state.error_msg}</Alert>
                </>
            )
        })
    }
}

export default CardsAdmin
