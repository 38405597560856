import React, {useState} from 'react'
import {Button} from 'react-bootstrap'
import ConfirmAlert from './ConfirmAlert'


export default (props) => {
    const needConfirmation = props.needConfirmation || false
    const buttonText = props.buttonText || 'Supprimer'

    const [show, setShow] = useState(false)
    const [dirty, setDirty] = useState(false)

    return (
        <div className={"d-flex justify-content-center " + props.className} >
            {
                show ? (
                    <ConfirmAlert 
                        handleConfirm={() => {setShow(false); props.handleDelete(); setDirty(false)}}
                        handleCancel={() => {setShow(false);  setDirty(false)}}

                    />
                ) : (
                    <Button variant="danger" onClick={() => {
                        setDirty(true)
                        if (needConfirmation) {
                            setShow(true)
                        } else {
                            props.handleDelete(); setDirty(false)
                        }
                    }}
                    disabled={dirty}>
                        {buttonText}
                    </Button>
                )
            }
            
            
        </div>
    )
}