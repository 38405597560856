import React from 'react'
import { getIdToken } from '../utils/auth'
import 'moment/locale/fr'
import {Form, Button, Col, Card, CardGroup} from 'react-bootstrap'
import set from 'date-fns/set'
import format from 'date-fns/format'
import axios from 'axios'

const weekDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']

class BookingsConfiguration extends React.Component {
  constructor(...args) {
    super(...args)

    this.saveConfiguration = this.saveConfiguration.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.timeSlots = []

    for (var i = 0; i < 24; i++) {
      this.timeSlots.push(format(set(new Date(), {hours:i, minutes:0}), 'HH:mm'))
      this.timeSlots.push(format(set(new Date(), {hours:i, minutes:30}), 'HH:mm'))
    }

    this.state = {
      configuration: {
        ranges: {
          0: [],
          1: [],
          2: [],
          3: [],
          4: [],
          5: [],
          6: [],
        },
        tz: 'Europe/Paris',
        nbChildren: 10
      }
    }
  }

  loadConfiguration() {
    axios({method: 'get', url:'/.netlify/functions/bookingConfiguration'}).then((response) => {
      this.setState({
        configuration: response.data.booking_configuration
      })
  })
  }

  componentDidMount() {
   this.loadConfiguration()
  }

  saveConfiguration() {
    axios({
      method: 'post', url:'/.netlify/functions/bookingConfiguration', 
      data: {booking_configuration: this.state.configuration,},
      headers: {token: getIdToken()}
    }).then((response) => {
      console.log(response)
      if (this.props.callback) {
        this.props.callback()
      }
    }).catch(err => {
      console.log(err)
    })
  }

  handleChange(day, index) {
    return (event) => {
      let old_ranges = [...this.state.configuration.ranges[day]]
      old_ranges[index] = {
        ...this.state.configuration.ranges[day][index],
        [event.target.name]: event.target.value,
      }
      this.setState(
        {
          configuration: {
            ...this.state.configuration,
            ranges: {
              ...this.state.configuration.ranges, 
              [day]: old_ranges
            }
          }
        }
      )
    }
  }

  getDayRanges(day) {
    return this.state.configuration.ranges[day].map((range, index) => {
      return <div key={index} className="my-3">
        <p className="my-1">début - fin</p>
        <Form>
          <Form.Row>
            <Col>
            <Form.Control as="select" name="start" value={range.start} onChange={this.handleChange(day, index)} >
              {
                this.timeSlots.map(timeslot => {
                  return <option key={'start_' + timeslot} value={timeslot}>{timeslot}</option>
                })
              }
            </Form.Control>
            </Col>
            <Col>
            <Form.Control as="select" name="end" value={range.end} onChange={this.handleChange(day, index)}>
              {
                this.timeSlots.map(timeslot => {
                  return <option key={'end_' + timeslot} value={timeslot}>{timeslot}</option>
                })
              }
            </Form.Control>
            </Col>
          </Form.Row>
        </Form>
      <Button className="mx-auto d-block my-2" variant="danger" onClick={(e) => {
        this.setState(
          prevState => {
            let old_ranges = [...prevState.configuration.ranges[day]]
            old_ranges.splice(index, 1)
            return (
              {
                configuration: {
                  ...prevState.configuration,
                  ranges: {
                    ...prevState.configuration.ranges, 
                    [day]: old_ranges
                  }
                }
            })
        })
      }}>
        Supprimer
      </Button>
    </div>
    })
  }

  render() {
    return (
      <>
          <CardGroup>
            {
            Object.keys(this.state.configuration.ranges).map(key => {
                return <Card key={key}>
                  <Card.Body>
                    <Card.Title>{weekDays[key]}</Card.Title>
                    {
                      this.getDayRanges(key)
                    }
                    <Button variant="info" onClick={
                      () => {
                        this.setState(state => {
                          return (
                            {
                              configuration: 
                              {
                                ...state.configuration,
                                ranges: {
                                  ...state.configuration.ranges,
                                  [key]: [...state.configuration.ranges[key], {
                                    start: '12:00', 
                                    end: '12:00'
                                  }]
                                }
                              }
                            }
                          )
                        })
                      }
                    }>
                      Ajouter un créneau
                    </Button>
                    
                  </Card.Body>
                </Card>    
              })
          }
          </CardGroup>
          <Form.Label>Nombre d'enfants maximum</Form.Label>
          <Form.Control
            type="number"
            name="nbChildren"
            value={this.state.configuration.nbChildren}
            onChange={(event) => this.setState({
              configuration: {
                ...this.state.configuration,
                nbChildren: event.target.value
              }
            })}
          />
          <Button variant="info" onClick={this.saveConfiguration} className="mx-auto d-block my-4">
            Sauvegarder les modifications
          </Button>
      </>
    )
  }

}

export default BookingsConfiguration
