import React, {useState} from "react"
import BookingsConfiguration from './BookingsConfiguration'
import { Button, Modal } from 'react-bootstrap'
import BookingsAdminCalendar from "../components/BookingsAdminCalendar"

export default () => {

    const [modalRanges, setModalRanges] = useState(false)

    return <>
            <Modal 
            show={modalRanges} onHide={() => setModalRanges(false)} 
            aria-labelledby="example-custom-modal-styling-title" size="xl"
            centered>
                <Modal.Header closeButton>
                    <Modal.Title>Créneaux</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                 <BookingsConfiguration callback={() => setModalRanges(false)}/> 
                </Modal.Body>
            </Modal>
            <Button className="mx-auto d-block" onClick={() => setModalRanges(true)}>Voir ou modifier les créneaux d'ouverture</Button>

            <h3 className='text-center py-3'>Calendrier des réservations</h3>
            <BookingsAdminCalendar />
        </>

}
    