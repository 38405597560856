import React from 'react'
import { Form, Button } from 'react-bootstrap'

class WorkshopAdminForm extends React.Component {

    workshopTypes = ["Ateliers parent-enfant", "Ateliers jeunes parents", "Formation professionnelle", "Enfants sans leurs parents"]

    constructor(props) {
        super(props);
        this.state = {
            workshopConfiguration: {
                nbPlaces: 6, price: 22, type: this.workshopTypes[0],
                ...props.workshopConfiguration
            },
            form_validated: false,
            dirty: false
        }
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidUpdate(prevProps) {
        if(prevProps.workshopConfiguration !== this.props.workshopConfiguration) {
            this.setState({
                workshopConfiguration: this.props.workshopConfiguration
            })
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            dirty: true,
            workshopConfiguration: {
                ...this.state.workshopConfiguration,
                [name]: value
            }
        })
    }

    renderForm() {
        return <>
            <Form.Label className="my-2 mx-2">Type d'atelier</Form.Label>
            <Form.Control
                as="select" name="type" value={this.state.workshopConfiguration.type}
                onChange={this.handleChange}>
                {
                    this.workshopTypes.map(workshopType => {
                        return <option key={'type_' + workshopType} value={workshopType}>{workshopType}</option>
                    })
                }
            </Form.Control>
            <Form.Label className="my-2 mx-2">Nombre de places</Form.Label>
            <Form.Control
                name="nbPlaces" type="number" onChange={this.handleChange} 
                value={this.state.workshopConfiguration.nbPlaces}
                required
            />
            <Form.Label className="my-2 mx-2">Prix</Form.Label>
            <Form.Control
                name="price" type="number" onChange={this.handleChange} 
                value={this.state.workshopConfiguration.price}
                required
            />


            <Button 
                disabled={! this.state.dirty}
                type="submit"
                className="btn btn-green d-block mx-auto my-4"
            >
                Enregistrer les modifications
            </Button>
        </>
    }

    render() {
        return (
                <Form
                    validated={this.state.form_validated} 
                    onSubmit={(e) => {
                        console.log(this.state.workshopConfiguration)
                        e.preventDefault();
                        this.setState({dirty: false});
                        this.props.handleSubmit(this.state.workshopConfiguration)}
                    }
                    className={"border rounded px-4 needs-validation " + this.props.className} noValidate>
                    {
                        this.renderForm()
                    }
                    
                </Form>
        )
    }
}

export default WorkshopAdminForm
