import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/privateRoute"
import Admin from "../components/Admin"

const App = () => (
  <Layout>
    <Router>
        <PrivateRoute path="/app/admin" component={Admin} />
    </Router>
  </Layout>
)
export default App
