import React from 'react'
import {Alert, Button, ListGroup, Modal, Tabs, Tab, Row, Col, Card} from 'react-bootstrap'
import axios from "axios"
import { StaticQuery, graphql } from "gatsby"
import WorkshopAdminForm from './WorkshopAdminForm'
import WorkshopAdminCalendar from './WorkshopAdminCalendar'
import ConfirmAlert from './ConfirmAlert'
import { getIdToken } from '../utils/auth'
import Img from "gatsby-image";

class WorkshopsAdmin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentWorkshop: null,
            error_msg: '',
            workshopsConfiguration: {},
            inputValue: '',
        }

        this.updateWorkshopConfiguration = this.updateWorkshopConfiguration.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.filterWorkshop = this.filterWorkshop.bind(this)
        this.renderWorkshops = this.renderWorkshops.bind(this)
        this.loadWorshopConfiguration = this.loadWorshopConfiguration.bind(this)
    }

    componentDidMount() {
        this.loadWorshopConfiguration()
    }

    loadWorshopConfiguration() {
        axios({method: 'get', url:'/.netlify/functions/workshopsConfiguration'}).then((response) => {
            this.setState({workshopsConfiguration: response.data.workshops_configuration})
            return response.data.workshops_configuration
        }).catch(err => {
            this.setState({
                workshopsConfiguration: {}
            })
        })
    }

    getWorkshops(render) {
        return <StaticQuery
            query={graphql`
            query WorkshopsAdmin {
                workshopsMdx:allMdx(filter: {fileAbsolutePath: {regex: "/content\/workshops\/.*/"}}) {
                    workshops:nodes {
                        workshop:frontmatter {
                            description
                            title
                            path
                            workshop_image {
                                childImageSharp {
                                    fluid(maxHeight: 150) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => render(data.workshopsMdx.workshops.map(workshop => workshop.workshop))}/>
    }

    updateWorkshopConfiguration(workshopConfiguration) {

        const path = workshopConfiguration.path
        const newWorkshopsConfiguration = {
            ...this.state.workshopsConfiguration,
            [path]: {...this.state.workshopsConfiguration[path], ...workshopConfiguration}
        }

        this.setState({
            error_msg: '',
            workshopsConfiguration: newWorkshopsConfiguration
        })

        axios({
            method: 'post', url:'/.netlify/functions/workshopsConfiguration', 
            data: {workshops_configuration: newWorkshopsConfiguration},
            headers: {token: getIdToken()}
        }).then(response => {
             //this.setState({currentWorkshop: {...this.state.currentWorkshop, dirty: false}})
        }).catch(err => {
            console.log(err)
            this.setState({error_msg: "Erreur lors de la sauvegarde"})
        })
    }

    hideModal() {
        this.setState({currentWorkshop: null})
    }

    renderWorkshopModal() {
        if (! this.state.currentWorkshop) {
            return
        }
        const {workshop, showModal} = this.state.currentWorkshop
        const configuration = this.state.workshopsConfiguration[workshop.path] || {path: workshop.path, title: workshop.title}
        return (
            <Modal 
                show={showModal} onHide={() => {
                    this.state.currentWorkshop.dirty ? 
                    this.setState({currentWorkshop: {...this.state.currentWorkshop, showCancel: true}}) :
                    this.hideModal()
                }} size="xl" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{workshop.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ConfirmAlert
                            className={this.state.currentWorkshop.showCancel ? '': 'd-none'}
                            text="Certaines modifications n'ont pas été sauvées, voulez-vous abandonner ces modifications ?"
                            handleConfirm={this.hideModal}
                            handleCancel={() => this.setState({currentWorkshop: {...this.state.currentWorkshop, showCancel: false}})}
                        />
                        <div className={this.state.currentWorkshop.showCancel ? 'd-none' : '' }>
                            <WorkshopAdminCalendar
                                workshopConfiguration={configuration}
                                handleSubmit={(workshop) => {
                                    this.updateWorkshopConfiguration(workshop)
                                    this.setState({currentWorkshop: {...this.state.currentWorkshop, dirty: false}})
                                    }
                                }
                                onChange={() => this.setState({
                                    currentWorkshop: {...this.state.currentWorkshop, dirty: true}
                                })}
                            />
                        </div>
                        <Alert variant="danger" className={this.state.error_msg ? '': 'd-none'}>
                            {this.state.error_msg}
                        </Alert>
                    </Modal.Body>
            </Modal>
        )
    }

    filterWorkshop() {
        return (
            <div>
                <h4>Filtrer : </h4>
                <input type={"text"}
                          value={this.state.inputValue}
                          onChange={(e) => this.setState({
                              inputValue:e.target.value
                          })}
                />
            </div>)
    }

    renderWorkshops(workshops) {

        const filteredWorkshops = workshops.filter(workshop => {
            return workshop.title.toLowerCase().includes(this.state.inputValue.toLowerCase())
        })

        return (
            <Row className={"row-cols-1 row-cols-md-4" + " w-xs-50"}>
                {
                    filteredWorkshops.map((workshop, index) => {
                        const configuration = this.state.workshopsConfiguration[workshop.path] || {path: workshop.path, title: workshop.title}
                        return (
                            <Col key={index} className="my-4">
                                <Card className="h-100">
                                    <Card.Header className="h4 text-green text-center mb-3">
                                        {workshop.title}
                                    </Card.Header>
                                    <Card.Img as={Img}
                                        fluid={workshop.workshop_image.childImageSharp.fluid} alt={workshop.title}
                                    />
                                    <Card.Body className="">
                                        <WorkshopAdminForm
                                            className="mx-auto"
                                            workshopConfiguration={configuration}
                                            handleSubmit={this.updateWorkshopConfiguration}
                                        />
                                    </Card.Body>
                                    <Card.Footer className="text-muted text-center">
                                        <Button onClick={() => {
                                            this.setState({
                                                currentWorkshop: {
                                                    showModal: true,
                                                    workshop: workshop
                                                }
                                            })
                                        }} className="my-1">Calendrier</Button>
                                    </Card.Footer>
                                </Card>

                            </Col>
                        )
                    })
                }
            </Row>
        )
    }

    render() {
        
        return this.getWorkshops(workshops => {
            return (
            <>
                {
                    this.filterWorkshop()
                }
                {
                    this.renderWorkshopModal()
                }
                {
                    this.renderWorkshops(workshops)
                }
            </>
            )
        })
    }
}

export default WorkshopsAdmin
