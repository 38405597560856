import React from 'react'
import {Alert, Button} from 'react-bootstrap'


export default (props) => {
    const text = props.text || 'Êtes-vous certain de vouloir continuer ?'

    return (
        <Alert className={props.className}>
            <p className="text-center">
                {text}
            </p>
            <div className="d-flex justify-content-center">
                <Button className="mx-2" onClick={props.handleConfirm} variant="danger">
                    Oui
                </Button>
                <Button className="mx-3" onClick={props.handleCancel}>
                    Non
                </Button>
            </div>
        </Alert>            
    )
}