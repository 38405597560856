import React from 'react'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import {Button, Card} from 'react-bootstrap'
import moment from 'moment-timezone'
import moment_base from 'moment'
import frLocal from 'moment/locale/fr'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import PropTypes from "prop-types"
import axios from "axios";
import {getIdToken} from "../utils/auth";
import DeleteButton from "./DeleteButton";

moment_base.locale('fr', frLocal);
moment.defineLocale('fr', moment_base.localeData()._config)
moment.locale('fr')

class WorkshopAdminCalendar extends React.Component {
    constructor(props) {
        super(props)

        const conf = props.workshopConfiguration
        const dates = conf.dates || []

        const events = dates.map((date, date_index) => {
            return {
                title: conf.title,
                start: moment(date.start).toDate(),
                end: moment(date.end).toDate()
            }
        })

        this.state = { 
            events: events,
            workshopConfiguration: {...conf, dates: dates},
            workshopsBookings: [],
            selectedEvent: {},
            dirty: false,
        }
        this.handleSelectEvent = this.handleSelectEvent.bind(this)
        this.handleSelectSlot = this.handleSelectSlot.bind(this)
        this.removeWorkshopDate = this.removeWorkshopDate.bind(this)
    }

    componentDidMount() {
        axios({method: 'get', url:'/.netlify/functions/workshopsRead', headers: {token: getIdToken()}}).then((response) => {
            this.setState({
                workshopsBookings: response.data.bookings
            })
        })
    }

    handleSelectEvent(event) {
        this.setState({selectedEvent: {event: event, showModal: true}})
    }

    removeWorkshopDate(event) {
        this.setState({
            events: this.state.events.filter(ev => ev !== event),
            workshopConfiguration: {
                ...this.state.workshopConfiguration,
                dates: this.state.workshopConfiguration.dates.filter(
                    date => date.start !== event.start.toISOString() || date.end !== event.end.toISOString()
                )
            },
            selectedEvent: {},
            dirty: true,
        }, () => {
            this.props.onChange(this.state.workshopConfiguration)
        })
    }

    handleSelectSlot({start, end}) {
        this.setState({
            workshopConfiguration: {
                ...this.state.workshopConfiguration,
                dates: [...this.state.workshopConfiguration.dates, {
                    start: start.toISOString(), end: end.toISOString()
                }],
            },
            events: [...this.state.events, {
                title: this.state.workshopConfiguration.title,
                start: start,
                end: end
            }],
            dirty: true
        }, () => this.props.onChange ? this.props.onChange(this.state.workshopConfiguration): null)
    }

    renderBookingCard(booking, index) {
        return (
            <Card key={'booking_' + index} className="h-100">
            <Card.Body className="h-25">
                <ul>
                    <li>nom: {booking.name}</li>
                    <li>âge: {booking.age}</li>
                    <li>email : {booking.email}</li>
                    <li>téléphone : {booking.phone}</li>
                </ul>
                <hr />
                <DeleteButton handleDelete={() => {
                    axios({
                        method: 'post', url:'/.netlify/functions/workshopCancel', data: {
                            workshop_id: booking._id,
                            email: booking.email,
                            force: true
                        },
                        headers: {token: getIdToken()}
                    }).then(response => {
                        console.log(response)
                        this.setState({
                            workshopsBookings: this.state.workshopsBookings.filter(book => book !== booking)
                        })
                    }).catch((err) => {
                        console.log(err)
                    })
                }}
                  buttonText="Annuler la réservation"
                  needConfirmation={true}
                />
            </Card.Body>
        </Card>
        )
    }

    renderInfos() {
        if (!this.state.selectedEvent.event) {
            return 
        }

        const event = this.state.selectedEvent.event
        const bookings = this.state.workshopsBookings.filter(booking => {
            const work = booking.workshop
            return work.workshop_title === event.title && work.date.start === event.start.toISOString() && work.date.end === event.end.toISOString()

        })

        return (
            <div className="mx-auto w-75 text-center mt-3">
                <h5>{event.title}</h5>
                <h5>Date : {moment(event.start).format('dddd LL')} de {moment(event.start).format('HH:mm')} à {moment(event.end).format('HH:mm')}</h5>
                <Button onClick={() => this.removeWorkshopDate(event)} variant="danger" className="my-2 d-block mx-auto">
                    Supprimer cet atelier
                </Button>
                <div className={"mt-4"}>
                    <h5>Liste des participants</h5>
                    {
                        bookings.map((booking, index) => {
                            return this.renderBookingCard(booking, index)
                        })
                    }
                </div>
            </div>
        )
    }

    render() {
        const localizer = momentLocalizer(moment)
        return (
            <>
            <Calendar selectable
            localizer={localizer}
            events={this.state.events}
            defaultView={Views.WEEK}
            views={[Views.WEEK]}
            culture='fr'
            style={{
                height: "600px"
            }}
            onSelectEvent={this.handleSelectEvent}
            scrollToTime={new Date()}
            min={new Date(0, 0, 0, 8, 30, 0)}
            max={new Date(0, 0, 0, 21, 30, 0)}
            onSelectSlot={this.handleSelectSlot}
            step={15}
            messages={{ 
              date: 'Date',
              time: 'Time',
              event: 'Event',
              allDay: 'Toute le journée',
              week: 'Semaine',
              work_week: 'Semaine ouvrée',
              day: 'Jour',
              month: 'Mois',
              previous: 'Précédent',
              next: 'Suivant',
              yesterday: 'Hier',
              tomorrow: 'Demain',
              today: "Aujourd'hui",
              agenda: 'Agenda',
              noEventsInRange: 'There are no events in this range.',
              showMore: function(e) {
              return '+' + e + ' more'
          }}}
        />
        {
            this.renderInfos()
        }
            <Button 
                className="btn btn-green d-block mx-auto my-4"
                onClick={e => {e.preventDefault(); this.props.handleSubmit(this.state.workshopConfiguration); this.setState({dirty: false})}}
                disabled={! this.state.dirty}
            >
                Enregistrer les modifications
            </Button>
        </>
        )
    }
}

WorkshopAdminCalendar.propTypes = {
    workshopConfiguration: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
}

export default WorkshopAdminCalendar