import React from "react"
import { Calendar, momentLocalizer, Views } from "react-big-calendar"
import { Button, Modal, Form, Card, Row, Col } from "react-bootstrap"
import moment from "moment-timezone"
import moment_base from "moment"
import frLocal from "moment/locale/fr"
import "react-big-calendar/lib/css/react-big-calendar.css"
import axios from "axios"
import { getIdToken } from "../utils/auth"
import DeleteButton from "./DeleteButton"

moment_base.locale("fr", frLocal)
moment.defineLocale("fr", moment_base.localeData()._config)
moment.locale("fr")

class BookingsAdminCalendar extends React.Component {
  constructor(...args) {
    super(...args)

    this.state = {
      events: [],
      showModal: false,
      showAdminModal: false,
      selectedEvent: {},
      bookingConfiguration: {
        specificConfig: {},
        ranges: {
          0: [],
          1: [],
          2: [],
          3: [],
          4: [],
          5: [],
          6: [],
        },
      },
    }
    this.handleSelectEvent = this.handleSelectEvent.bind(this)
    this.handleEventPropGetter = this.handleEventPropGetter.bind(this)
    this.saveSpecificConfig = this.saveSpecificConfig.bind(this)
  }

  loadBookings() {
    axios({ method: "get", url: "/.netlify/functions/bookingConfiguration" })
      .then((response) => {
        this.setState({
          bookingConfiguration: response.data.booking_configuration,
        })
        return response.data.booking_configuration
      })
      .then((booking_configuration) => {
        axios({
          method: "get",
          url: "/.netlify/functions/bookings",
          headers: { token: getIdToken() },
        }).then((response) => {
          let events = []
          let bookings = {}
          response.data.bookings.forEach((booking) => {
            const rangeKey = booking.datetime.start + booking.datetime.end
            if (!(booking.datetime.date in bookings)) {
              bookings[booking.datetime.date] = { [rangeKey]: [booking] }
            } else {
              if (!(rangeKey in bookings[booking.datetime.date])) {
                bookings[booking.datetime.date][rangeKey] = [booking]
              } else {
                bookings[booking.datetime.date][rangeKey] = [
                  ...bookings[booking.datetime.date][rangeKey],
                  booking,
                ]
              }
            }
          })

          Object.keys(bookings).forEach((bookingKey) => {
            Object.keys(bookings[bookingKey]).forEach((rangeKey) => {
              const booking = bookings[bookingKey][rangeKey][0]
              const datetime = booking.datetime
              events.push({
                title: `${bookings[bookingKey][rangeKey].length} réservations`,
                start: moment
                  .tz(datetime.date + "T" + datetime.start, "Europe/Paris")
                  .toDate(),
                end: moment
                  .tz(datetime.date + "T" + datetime.end, "Europe/Paris")
                  .toDate(),
                bookings: bookings[datetime.date][rangeKey],
                datetime: datetime,
              })
            })
          })

          let currentDate = moment()
          for (let nDay = 0; nDay < 200; nDay++) {
            const ranges = booking_configuration.ranges[currentDate.day()]
            for (let i = 0; i < ranges.length; i++) {
              let range = ranges[i]
              const startDate = moment.tz(
                currentDate.format("YYYY-MM-DDT") + range.start,
                "Europe/Paris"
              )
              const endDate = moment.tz(
                currentDate.format("YYYY-MM-DDT") + range.end,
                "Europe/Paris"
              )
              if (moment() < endDate) {
                const datetime = {
                  date: currentDate.format("YYYY-MM-DD"),
                  start: range.start,
                  end: range.end,
                }
                const rangeKey = datetime.start + datetime.end
                if (
                  !(datetime.date in bookings) ||
                  !(rangeKey in bookings[datetime.date])
                ) {
                  let event = {
                    title: "Configurer ce créneau",
                    start: startDate.toDate(),
                    end: endDate.toDate(),
                    datetime: datetime,
                  }
                  const specificConfig =
                    this.state.bookingConfiguration?.specificConfig?.[
                      datetime.date
                    ]?.[rangeKey]
                  if (specificConfig) {
                    event["closed"] = specificConfig["closed"] || false
                    event["full"] = specificConfig["full"] || false
                  }
                  events.push(event)
                }
              }
            }
            currentDate = currentDate.add(1, "days")
          }
          this.setState({ events: events })
        })
      })
  }

  componentDidMount() {
    this.loadBookings()
  }

  handleSelectEvent(event) {
    if (event.bookings) {
      this.setState({ showModal: true, selectedEvent: event })
    } else {
      this.setState({ showAdminModal: true, selectedEvent: event })
    }
  }

  handleEventPropGetter(event, start, end, isSelected) {
    let style = {
      backgroundColor: "#4D9791",
      borderRadius: 0,
    }

    if (event.bookings) {
      style["backgroundColor"] = "#24564E"
    }

    if (event.closed) {
      style["backgroundColor"] = "grey"
    }

    if (event.full) {
      style["backgroundColor"] = "red"
    }

    return {
      style: style,
    }
  }

  renderModal() {
    if (!this.state.selectedEvent.bookings) {
      return
    }

    const event = this.state.selectedEvent
    const firtsBooking = event.bookings[0]

    const datetime = this.state.selectedEvent["datetime"]
    const rangeKey = datetime.start + datetime.end

    let specificConfig = { ...this.state.bookingConfiguration.specificConfig }

    if (!(datetime.date in specificConfig)) {
      specificConfig[datetime.date] = {}
    }

    if (!(rangeKey in specificConfig[datetime.date])) {
      specificConfig[datetime.date][rangeKey] = {
        full: false,
        nbChildren: null,
      }
    }

    let label = ""
    if (specificConfig[datetime.date][rangeKey].full === false) {
      label = "Le créneau est ouvert"
    } else {
      label = "Le créneau est complet"
    }

    return (
      <Modal
        show={this.state.showModal}
        onHide={() => this.setState({ showModal: false, selectedEvent: {} })}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Réservations du{" "}
            {moment(firtsBooking.datetime.date).format("dddd LL")} de{" "}
            {firtsBooking.datetime.start} à {firtsBooking.datetime.end}
          </Modal.Title>
        </Modal.Header>
        <Row className="row-cols-1 row-cols-md-3 w-xs-50 justify-content-center">
          {event.bookings.map((booking, index) => {
            return (
              <Col key={"col_booking_" + index}>
                <Card key={"booking_" + index} className="h-100">
                  <Card.Body className="h-25">
                    <ul>
                      <li>nom adulte: {booking.name}</li>
                      <li>
                        nom enfant : {booking.child_name || booking.name_child}
                      </li>
                      <li>
                        âge de l'enfant :{" "}
                        {booking.child_age || booking.age_child}
                      </li>
                      <li>email : {booking.email}</li>
                      <li>téléphone : {booking.phone}</li>
                    </ul>
                    <hr />
                    <DeleteButton
                      handleDelete={() => {
                        axios({
                          method: "post",
                          url: "/.netlify/functions/bookingCancel",
                          data: {
                            booking_id: booking._id,
                            email: booking.email,
                            force: true,
                          },
                          headers: { token: getIdToken() },
                        })
                          .then((response) => {
                            const new_bookings = event.bookings.filter(
                              (bo, ind) => index !== ind
                            )
                            let events = [...this.state.events]
                            let index_event = null
                            for (let ei = 0; ei < events.length; ei++) {
                              if (
                                events[ei].datetime ===
                                this.state.selectedEvent.datetime
                              ) {
                                index_event = ei
                                break
                              }
                            }
                            let new_event = {}
                            if (new_bookings.length > 0) {
                              new_event = {
                                ...event,
                                bookings: new_bookings,
                                title: `${new_bookings.length} réservations`,
                              }
                              events[index_event] = new_event
                            } else {
                              events.splice(index_event, 1)
                            }
                            this.setState({
                              events: events,
                              selectedEvent: new_event,
                            })
                          })
                          .catch((err) => {
                            console.log(err)
                          })
                      }}
                      buttonText="Annuler la réservation"
                      needConfirmation={true}
                      className="mb-2"
                    />
                  </Card.Body>
                </Card>
              </Col>
            )
          })}
        </Row>
        <Form className="my-2 mx-2">
          <Form.Check
            type="switch"
            id="custom-switch"
            label={label}
            checked={specificConfig[datetime.date][rangeKey].full}
            onChange={(e) => {
              specificConfig[datetime.date][rangeKey] = {
                ...specificConfig[datetime.date][rangeKey],
                full: e.target.checked,
              }
              this.setState({
                selectedEvent: {
                  ...this.state.selectedEvent,
                  full: specificConfig[datetime.date][rangeKey].full,
                },
                bookingConfiguration: {
                  ...this.state.bookingConfiguration,
                  specificConfig: specificConfig,
                },
              })
            }}
          />
          <Form.Label>Nombre d'enfants maximum</Form.Label>
          <Form.Control
            className="w-25"
            type="number"
            name="nbChildren"
            value={specificConfig[datetime.date][rangeKey].nbChildren}
            onChange={(e) => {
              specificConfig[datetime.date][rangeKey] = {
                ...specificConfig[datetime.date][rangeKey],
                nbChildren: e.target.value,
              }
              this.setState({
                selectedEvent: {
                  ...this.state.selectedEvent,
                  nbChildren:
                    specificConfig[datetime.date][rangeKey].nbChildren,
                },
                bookingConfiguration: {
                  ...this.state.bookingConfiguration,
                  specificConfig: specificConfig,
                },
              })
            }}
          />
          <Button
            onClick={this.saveSpecificConfig}
            className="my-2 d-block mx-auto"
          >
            Enregistrer les modifications
          </Button>
        </Form>
      </Modal>
    )
  }

  saveSpecificConfig() {
    axios({
      method: "post",
      url: "/.netlify/functions/bookingConfiguration",
      data: { booking_configuration: this.state.bookingConfiguration },
      headers: { token: getIdToken() },
    })
      .then((response) => {
        let events = [...this.state.events]
        this.state.events.forEach((event, index) => {
          if (event.datetime === this.state.selectedEvent.datetime) {
            events[index] = { ...this.state.selectedEvent }
          }
        })
        this.setState({
          showAdminModal: false,
          selectedEvent: {},
          events: events,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  renderAdminModal() {
    if (!this.state.selectedEvent.datetime) {
      return
    }

    const datetime = this.state.selectedEvent["datetime"]
    const rangeKey = datetime.start + datetime.end

    let specificConfig = { ...this.state.bookingConfiguration.specificConfig }

    if (!(datetime.date in specificConfig)) {
      specificConfig[datetime.date] = {}
    }

    if (!(rangeKey in specificConfig[datetime.date])) {
      specificConfig[datetime.date][rangeKey] = {
        closed: false,
      }
    }

    let label = ""
    if (specificConfig[datetime.date][rangeKey].closed === false) {
      label = "Le créneau est ouvert"
    } else {
      label = "Le créneau est fermé"
    }

    return (
      <Modal
        show={this.state.showAdminModal}
        onHide={() =>
          this.setState({ showAdminModal: false, selectedEvent: {} })
        }
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modification du créneau</Modal.Title>
        </Modal.Header>
        <Form className="my-2 mx-2">
          <Form.Check
            type="switch"
            id="custom-switch"
            label={label}
            checked={!specificConfig[datetime.date][rangeKey].closed}
            onChange={(e) => {
              specificConfig[datetime.date][rangeKey] = {
                closed: !e.target.checked,
              }
              this.setState({
                selectedEvent: {
                  ...this.state.selectedEvent,
                  closed: specificConfig[datetime.date][rangeKey].closed,
                },
                bookingConfiguration: {
                  ...this.state.bookingConfiguration,
                  specificConfig: specificConfig,
                },
              })
            }}
          />
          <Form.Label>Nombre d'enfants maximum</Form.Label>
          <Form.Control
            className="w-25"
            type="number"
            name="nbChildren"
            value={specificConfig[datetime.date][rangeKey].nbChildren}
            onChange={(e) => {
              specificConfig[datetime.date][rangeKey] = {
                ...specificConfig[datetime.date][rangeKey],
                nbChildren: e.target.value,
              }
              this.setState({
                selectedEvent: {
                  ...this.state.selectedEvent,
                  nbChildren:
                    specificConfig[datetime.date][rangeKey].nbChildren,
                },
                bookingConfiguration: {
                  ...this.state.bookingConfiguration,
                  specificConfig: specificConfig,
                },
              })
            }}
          />
          <Button
            onClick={this.saveSpecificConfig}
            className="my-2 d-block mx-auto"
          >
            Enregistrer les modifications
          </Button>
        </Form>
      </Modal>
    )
  }

  render() {
    const localizer = momentLocalizer(moment)
    return (
      <>
        {this.renderModal()}
        {this.renderAdminModal()}
        <Calendar
          localizer={localizer}
          events={this.state.events}
          defaultView={Views.WEEK}
          views={[Views.MONTH, Views.WEEK]}
          culture="fr"
          style={{
            height: "600px",
          }}
          onSelectEvent={this.handleSelectEvent}
          eventPropGetter={this.handleEventPropGetter}
          scrollToTime={new Date()}
          min={new Date(0, 0, 0, 10, 0, 0)}
          max={new Date(0, 0, 0, 18, 30, 0)}
          messages={{
            date: "Date",
            time: "Time",
            event: "Event",
            allDay: "Toute le journée",
            week: "Semaine",
            work_week: "Semaine ouvrée",
            day: "Jour",
            month: "Mois",
            previous: "Précédent",
            next: "Suivant",
            yesterday: "Hier",
            tomorrow: "Demain",
            today: "Aujourd'hui",
            agenda: "Agenda",
            noEventsInRange: "There are no events in this range.",
            showMore: function (e) {
              return "+" + e + " more"
            },
          }}
        />
      </>
    )
  }
}

export default BookingsAdminCalendar
